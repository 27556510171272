import React from 'react'
import TextareaAutosize from 'react-autosize-textarea'

export const TextareaAuto = ({ placeholder, style, value, onChange, onKeyPress, autoFocus }) => (
  <TextareaAutosize
    placeholder={placeholder || ''} style={{ ...styles.textAreaStyle, ...style }}
    autoFocus={autoFocus} value={value || ''} onChange={onChange} onKeyPress={onKeyPress}
  />
)

const styles = {
  textAreaStyle: {
    color: '#32324e',
    width: '100%',
    height: 80,
    minHeight: 80,
    maxHeight: 450,
    paddingLeft: 10,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
    border: 'none',
    borderRadius: '20px',
    backgroundColor: 'white',
    boxShadow: 'none',
    resize: 'none',
    fontFamily: 'Lato,Source Sans Pro',
    fontSize: '14px',
    outlineColor: 'transparent',
    outlineWidth: '0px'
  }
}
